// 经典客户-文字列表
<template>
  <div class="classicCustomer-list">
    <div class="list-banner" :style="`background-image:url(${typeItem.img})`">
      <div :class="['list-title', typeItem.type=='tswxzc'?'color-active':'']">{{typeItem.name}}</div>
    </div>
    <el-row class="atlas-list">
      <el-col class="atlas-item" :span="typeItem.type=='tswxzc'?24:12" v-for="(item,index) in data" :key="index">
        <p>{{item.name}}</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getCase } from '@/api'
import { mapGetters } from "vuex"
export default {
  computed: {
    ...mapGetters(['getNavItem'])
  },
  data () {
    return {
      type: '',
      data: [],
      lists: [
        { type: 'kyys', name: '科研院所', img: require('@/assets/images/jdkh-img1.png') },
        { type: 'swyy', name: '生物医药', img: require('@/assets/images/jdkh-img1.png') },
        { type: 'tswxzc', name: '特殊无形资产', img: require('@/assets/images/jdkh-img2.png') },
      ],
      typeItem: {}
    };
  },
  mounted () {
    this.getData()
  },

  methods: {
    async getData () {
      this.type = this.$route.name
      this.typeItem = this.lists.find(i => i.type == this.type)
      let { data } = await getCase(this.getNavItem.tag)
      this.data = data
    }
  },
};
</script>

<style scoped lang="less">
.list-banner {
  height: 154px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  .list-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-color: #fff;
    padding: 20px 48px;
    font-size: 26px;
    font-weight: bold;
    &.color-active {
      color: #cf4036;
    }
  }
}
.atlas-list {
  padding: 57px 90px;
  background-color: #fff;
  .atlas-item {
    margin: 15px 0;
  }
  p {
    line-height: 20px;
    position: relative;
    margin-left: 30px;
    color: #221815;
    &::before {
      content: "";
      position: absolute;
      top: 30%;
      left: -30px;
      width: 10px;
      height: 10px;
      background-color: #fbd690;
      border-radius: 50%;
    }
  }
}
</style>
